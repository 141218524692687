<ng-container *ngIf="notification">
    <a *ngIf="notification.task; then task else default"
       container
       target="_blank"
       (click)="setSeen()"
       [routerLink]="['/app/tasks', notification.task.id]">
            <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
</ng-container>

<ng-template #task>
    <a *ngIf="!notification.task.advertisingTaskType else advMaterialTask"
       container
       target="_blank"
       (click)="setSeen()"
       [routerLink]="['/app/tasks', notification.task.id]">
            <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
</ng-template>

<ng-template #advMaterialTask>
    <div container (click)="openDialog()">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
</ng-template>

<ng-template #default>
    <div container>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
</ng-template>

<ng-template #content>
    <div avatar>
        <div class="avatar">
            <img *ngIf="getAvatar(); else name"
                 [src]="getAvatar()"
                 class="avatar"
                 alt="{{ notification.triggeringUser?.firstName }} {{ notification.triggeringUser?.lastName }}">
            <ng-template #name>
                <div>
                    {{ notification.triggeringUser?.firstName.charAt(0).toUpperCase() }}{{ notification.triggeringUser?.lastName.charAt(0).toUpperCase() }}
                </div>
            </ng-template>
        </div>
    </div>
    <div message>
        <div title>
            <span name>{{notification.triggeringUser.firstName}} {{notification.triggeringUser.lastName}}</span>
            <span date>{{notification.createdAt | date: 'dd.MM.yy H:mm:ss'}}</span>
        </div>
        <div text [innerHTML]="notification.text"></div>
    </div>
    <button mat-icon-button (click)="setSeen($event)">
        <mat-icon>cancel</mat-icon>
    </button>
</ng-template>
