<div *ngIf="user" class="container">

    <div class="avatar">
        <img *ngIf="getImage(); else name" [ngSrc]="getImage()"
             height="40"
             width="40"
             class="avatar"
             alt="{{ user?.firstName }} {{ user?.lastName }}">
        <ng-template #name>
            <div>{{ user?.firstName.charAt(0).toUpperCase() }}{{ user?.lastName.charAt(0).toUpperCase() }}</div>
        </ng-template>
    </div>
    <button mat-button class="user_button" [matMenuTriggerFor]="menu">
        <span class="name">
            {{ user.firstName }} {{ user.lastName }}
            <fa-icon [icon]="faAngleDown"></fa-icon>
        </span>
    </button>

    <mat-menu #menu class="topbar_menu_panel">
        <a class="menu_button" mat-menu-item [routerLink]="['user/my-profile']">
            <span>Mein Profil</span>
        </a>

        <a class="menu_button" mat-menu-item (click)="logout()">
            <span>Abmelden</span>
        </a>
    </mat-menu>
</div>
