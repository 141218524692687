import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {User} from '../../models/user/user.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {UserFilter} from '../../models/user/user-filter.model';
import {finalize, first, map, mergeMap, tap} from 'rxjs/operators';
import {CrudService} from '../crud.service';
import {ListView} from '../../models/list-view/list-view.model';
import * as _ from 'lodash';
import {UserClaim, UserClaimsDTO} from '../../models/user/user-claims.model';
import {ListName} from '../../custom-types/list-name.type';
import {ClaimsConstants} from '../../constants/claims.constants';
import {IFilterableEntityService} from '../filterable-entity.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends CrudService<User> implements IFilterableEntityService<UserFilter> {
    // TODO: refactoring - static makes no real sense in a singleton service
    private static readonly myUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    filterRoute = `${environment.backendUrl}/filters/user`;
    currentUser: Observable<User>;

    private avatarRoute = `${environment.uploadUrl}/avatars`;
    private claims: UserClaimsDTO;

    constructor(http: HttpClient,
                private cookieService: CookieService) {
        super(http, `${environment.backendUrl}/users`);

        this.currentUser = UserService.myUser.asObservable();
    }

    public static getActiveListView(list: ListName): ListView {
        return UserService.myUser.value.activeListViews.find(entry => entry.list === list);
    }

    public getFilters(fieldName?: string): Observable<UserFilter> {
        let route = this.filterRoute;
        if (fieldName) {
            route += `?fieldName=${fieldName}`;
        }
        return this.http.get(route).pipe(
            map(obj => new UserFilter().deserialize(obj))
        );
    }

    public patch(body: any, id: number) {
        return super.patch(body, id).pipe(finalize(() => {
            if (UserService.myUser && id === UserService.myUser.value.id) {
                this.updateMyProfile().subscribe();
            }
        }));
    }

    public put(body: User) {
        return super.put(body).pipe(finalize(() => {
            if (UserService.myUser && body.id === UserService.myUser.value.id) {
                this.updateMyProfile().subscribe();
            }
        }));
    }

    public get(id: number): Observable<User> {
        if (id === UserService.myUser.value?.id) {
            return super.get(id).pipe(tap(user => UserService.myUser.next(user)));
        }
        return super.get(id);
    }

    public getForFormField(formField: string): Observable<User[]> {
        const route = `${this.route}/${formField}`;
        return this.http.get<User[]>(route).pipe(
            map(response => response.map(obj => this.newInstance(obj)))
        );
    }

    /** @todo refactoring: public get currentUserSnapshot()... */
    public getCurrentUser(): User {
        return UserService.myUser.getValue();
    }

    public login(eMail: string, pwd: string): Observable<any> {
        const route = `${this.route}/login`;
        const body = {
            eMailAddress: eMail,
            password: pwd
        };

        return this.http.post<any>(route, body, {observe: 'response'});
    }

    public logout(): void {
        this.cookieService.delete('PHPSESSID', '/');
    }

    // <editor-fold desc='Password'>
    public requestPassword(eMail: string): Observable<void> {
        const route = `${this.route}/request-password`;
        const body = {
            eMailAddress: eMail
        };

        return this.http.post<void>(route, body);
    }

    public setPassword(pwd: string, confirmPwd: string, token: string): Observable<void> {
        const route = `${this.route}/set-password/${token}`;
        const body = {
            password: {
                first: pwd,
                second: confirmPwd
            }
        };

        return this.http.post<void>(route, body);
    }

    public changePassword(oldPassword: string, newPassword: string, confirmPassword: string): Observable<void> {
        const route = `${this.route}/change-password`;
        const body = {
            oldPassword,
            password: {
                first: newPassword,
                second: confirmPassword
            }
        };

        return this.http.post<void>(route, body);
    }

    // </editor-fold>

    public isLoggedIn(): boolean {
        return this.cookieService.check('PHPSESSID');
    }

    public checkClaim(claimData: any): Observable<boolean> {
        if (this.claims) {
            return of(this.hasClaim(claimData));
        }

        return this.getClaims()
            .pipe(mergeMap(
                () => of(this.hasClaim(claimData))
            ));
    }

    public hasClaim(claimData: UserClaim): boolean {
        if (!claimData || !claimData.claim) {
            return false;
        }

        if (this.claims[ClaimsConstants.adminClaim.claim]) {
            return true;
        }

        if (this.claims[claimData.claim]) {
            if (claimData.create) {
                return this.claims[claimData.claim].create;
            }
            if (claimData.write) {
                return this.claims[claimData.claim].write;
            }
            return true;
        }

        return false;
    }

    public getClaims() {
        const route = `${this.route}/user-priviliges`;
        return this.http.get<UserClaimsDTO>(route)
            .pipe(
                first(),
                tap(claims => {
                    this.claims = claims;
                }));
    }

    // <editor-fold desc='Image'>
    public uploadImage(id: number, image: File): Observable<void> {
        const route = `${this.route}/${id}/avatar`;
        const uploadData = new FormData();
        uploadData.append('avatarImage', image, image.name);
        return this.http.post<void>(route, uploadData);
    }

    public deleteImage(id: number): Observable<void> {
        const route = `${this.route}/${id}/avatar`;
        return this.http.delete<void>(route);
    }

    public getImage(fileName: string) {
        if (fileName) {
            return `${this.avatarRoute}/${fileName}`;
        }
        return '';
    }

    // </editor-fold>

    public updateMyProfile(): Observable<User> {
        const route = `${this.route}/my-profile`;
        return this.http.get<User>(route).pipe(
            first(),
            tap(user => {
                if (user !== UserService.myUser.value) {
                    UserService.myUser.next(user);
                }
            })
        );
    }

    public setActiveListView(listView: ListView) {
        const listViews = _.cloneDeep(UserService.myUser.value.activeListViews);

        const index = listViews.findIndex(entry => entry.list === listView.list);

        if (index >= 0) {
            if (listView.id !== null) {
                listViews[index] = listView;
            } else {
                listViews.splice(index, 1);
            }
        } else if (listView.id !== null) {
            listViews.push(listView);
        }

        const user = UserService.myUser.getValue();
        user.activeListViews = listViews;
        UserService.myUser.next(user);

        const body = {
            activeListViews: listViews.map(list => list.id)
        };

        return this.patch(body, UserService.myUser.value.id);
    }

    public isInTeam(teamName: string) {
        return UserService.myUser.getValue().teams.some(team => team.name === teamName);
    }

    public getResponsibleUsersAdvertisement() {
        const route = `${this.route}/advertisingMaterial-responsibleUserAdvertisement`;
        return this.http.get<User[]>(route);
    }

    protected newInstance(obj: any): User {
        return new User().deserialize(obj);
    }
}
